import React from 'react'

function ExperienceBlock({experienceData}) {
  return (
    <>
    {experienceData?.map((data) => 
    <li className='mb-12 experienceCard' key={data.id}>
        <div>
            <p className='text-sm font-semibold uppercase tracking-wide text-slate-500 '>
            {data.when}
            </p>
            <div className='z-10 sm:col-span-6'>
                <h3 className='font-medium leading-snug text-slate-200 mb-2'> {data.role} - 
                    <span className='ml-2'>
                        <a href={data.link} target='blank' className='inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-blue-500 focus-visible:text-blue-500'> {data.title} <i className="ml-2 fa-solid fa-arrow-up-right-from-square"></i></a>
                    </span> 
                </h3>
            <p className='p-base '> {data.description} </p>
            <div className='mt-4'>
                <ul className='flex flex-wrap' aria-label='Technologies used'>
                    {data?.technologies?.map((technology) => 
                        <li className='technologyBubble mr-1.5 mt-2' key={technology.name}> 
                            <p className='text-white'>{technology.name}</p>  
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
  </li>
    )}
  </>
  )
}

export default ExperienceBlock