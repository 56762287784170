import React from 'react';
import LandingPage from './pages/LandingPage';
import './style.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ErrorNotFound from './error/ErrorNotFound';
import Header from './components/Header';
function App() {
	return (
		<>
			<div className='mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0'>
				<Router>
					<div className='w-full md:flex md:justify-between'>
						<Header />
						<main className='pt-24 lg:w-1/2 lg:py-24'>
							<Routes>
								<Route exact path='/' element={<LandingPage />} />
								<Route path='*' element={<ErrorNotFound />} />
							</Routes>
						</main>
					</div>
				</Router>
			</div>
		</>
	);
}

export default App;
