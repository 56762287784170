import React from 'react'
import Avatar from '../images/icons/Avatar.png'

function About() {
  return (
    <section id='about' className='mb-12 rounded-xl mx-auto h-full md:mb-0 md:max-w-6xl md:max-h-screen'>
      <div className='sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0'>
          <h2 className='text-white text-xl font-MontSerrat'> About </h2> 
      </div>
      <div className="mb-8 md:inline-flex">
          <p className="mb-4 p-base">Back in 2017, I got my first line of code written and introduced to the creative world of programming. This created the path to further explore coding and web development. 
            Fast-forward to today, and I've had the privilege of being a software developer at two consulting companies 
              <a href="https://www.forefront.se/" target="blank" className="text-white hover:text-blue-500 focus-visible:text-blue-500"> Forefront </a> and <a href="https://www.tietoevry.com/" target="blank" className="text-white hover:text-blue-500">Tietoevry. </a> 
            Building software for several clients. 
          </p>
        <img className=' rounded-r-3xl w-52 mx-auto' src={Avatar} alt='The developer portrait'/>
      </div>
      <p className="mb-4 p-base">My main focus these days is building software solutions for our clients at <a href="https://www.forefront.se/" target="blank" className="text-white hover:text-blue-500 focus-visible:text-blue-500">Forefront. </a>
      I find it the most fun when smooth design and well built code is implemented in software building process.</p>
      <p className="mb-4 p-base">When I'm not at the computer, you can find me playing football, reading, hanging out with my friends.</p>
    </section>
  )
}

export default About