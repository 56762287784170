import React from 'react'
import '../style.css'
import About from './About';
import Projects from './Projects';
import Experience from './Experience';

function LandingPage() {
  return (
    <>
      <About />
      <Experience/>
      <Projects/>
    </>
  )
}

export default LandingPage