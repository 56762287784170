import React from 'react'

function ErrorNotFound() {
  return (
    <div className='text-white w-full mx-auto mt-24 relative md:max-w-4xl'>
       <h1 className='text-2xl md:text-4xl mb-2 ml-2'>Oops! That page can't be found.</h1>
        <p className='ml-2'>
          It looks like nothing was found at this location,
          use the link below to go back.
        </p>
        <div className='mt-20 ml-2'>
          <a className='text-white w-52 h-12 flex gap-4 items-center hover:delay-200 justify-center rounded-xl border-solid border-2 border-white p-2 hover:bg-white hover:text-black z-200' href='/'>I want to go back 
            <i className="fa-solid fa-arrow-right relative text-3xl"></i>
          </a>
        </div>
    </div>
  )
}

export default ErrorNotFound