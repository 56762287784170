import React from 'react'
function ProjectsBlock({projectData}) {
    console.log(projectData, 'projectData')
    return (
        <>
        {projectData?.map((data) => 
        <li className='mb-12 experienceCard' key={data.id}>
            <div>
                {data?.image_url && (
                            <img className='w-48 rounded-lg mb-4 p-2 md:mb:0 md:float-right' src={require(`../images/${data.image_url}`)} alt={data.imageAltText} />
                )}
                <div className='z-10 sm:col-span-6'>
                        <h3 className='font-medium leading-snug text-slate-200 mb-2'>
                            <span>
                                <a href={data.link} target='blank' className='inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-blue-500 focus-visible:text-blue-500'> {data.title} <i className="ml-2 fa-solid fa-arrow-up-right-from-square"></i></a>
                            </span> 
                        </h3>
                <p className='p-base '> {data.description} </p>
                <div className='mt-4'>
                    <ul className='flex flex-wrap' aria-label='Technologies used'>
                        {data?.technologies?.map((technology) => 
                            <li className='technologyBubble mr-1.5 mt-2' key={technology.name}> 
                                <p className='text-white'>{technology.name}</p>  
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
      </li>
        )}
      </>
      )
}

export default ProjectsBlock