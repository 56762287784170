import React from 'react'

function Header() {
  return (
    <header className='w-full md:sticky md:top-0  md:max-h-screen md:w-1/2 md:flex-col md:justify-between md:py-24'>
      <div className='w-80'>
        <a href='/'> <h1 className='text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl font-MontSerrat'> Simon Palm </h1></a>
        <h2 className='mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl font-MontSerrat'> Software Developer</h2>
        <p className='mt-4 max-w-xs text-gray-400 font-MontSerrat'>I build awesome web experiences with depth and rich user experience.</p>   
      </div>
      <div className='items-center h-full mt-10'>
          <div>
            <ul className='hidden md:block'>
              <li>
                <a className='link scroll-smooth' 
                href='#about'>
                <p>About</p>
                </a>
              </li>
              <li>
                <a className='link scroll-smooth' 
                href='#experience'>
                <p>Experience</p>
                </a>
              </li>
              <li>
                <a className='link scroll-smooth' 
                href='#projects'>
                <p>Projects</p>
                </a>
              </li>
            </ul>
            <div className='mt-10'>
              <ul className='inline-flex gap-8'>
                <li>
                  <a href='https://github.com/PalmenDev' className='text-3xl hoverHeaderIcons' title='GitHub'>
                    <i className="fa-brands fa-github"></i>
                  </a>
                </li>
                <li>
                  <a href='https://www.linkedin.com/in/simon-palm-85678b1b9/' className='text-3xl hoverHeaderIcons' title='Linkedin'>
                  <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>

                <li>
                  <a href='mailto:simonpalmdev@hotmail.com' className='text-3xl hoverHeaderIcons' title='Contact'>
                  <i className="fa-solid fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
  )
}

export default Header