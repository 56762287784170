import React from 'react'
import ExperienceJson from '../data/experience.json'
import ExperienceBlock from '../components/ExperienceBlock'

function Experience() {
  return (
    <section id='experience' className='mb-12 rounded-xl mx-auto h-full md:mb-0 md:max-w-6xl md:max-h-screen md:scrollbar-hidden md:overflow-y-auto'>
      <div className='sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0'>
          <h2 className='text-white text-xl font-MontSerrat'> Experience </h2> 
      </div>
      <div className='mt-4'>
          <ul>
            <ExperienceBlock experienceData={ExperienceJson.experience} />
          </ul>
        </div>
    </section>
  )
}

export default Experience