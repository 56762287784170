import React from 'react'
import ProjectData from '../data/highlightProjects.json'
import ProjectsBlock from '../components/ProjectsBlock'
function Projects() { 
  return (
    <section id='projects' className='mb-16 scroll-mt-16 md:mb-24 md:scroll-mt-24 md:max-h-screen md:scrollbar-hidden md:overflow-y-auto lg:mb-36 '>
        <div className='sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0'>
          <h2 className='text-white text-xl font-MontSerrat'> Projects </h2>
        </div>
        <div className='mt-4'>
          <ul>
            <ProjectsBlock projectData={ProjectData.projects} />
          </ul>
        </div>
    </section>
  )
}

export default Projects